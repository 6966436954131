import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Image from '../components/Image';
import Layout from '../components/Layout';
import { decodeEntities } from '../utils/htmlParse';
import { RelatedPosts } from '../components/Posts/RelatedPosts';
import Previewable from '../components/Previewable';
import * as AcfLayout from '../acf';
import SEO from '../components/SEO';
import './post.scss';

const {
  SocialShareBlock,
  SubscribeFormBlock,
  TestimonialsBlock,
  LogosBlock,
  ContactInformationBlock
} = AcfLayout;

const AcfComponent = ({ item, location, siteMetadata, componentName }) => {
  const ComponentName = AcfLayout[componentName];
  return (
    <ComponentName
      location={location}
      siteMetadata={siteMetadata}
      {...item}
    />
  );
};

export const BlogPostTemplate = (props) => {
  const {
    title,
    date,
    featuredImage,
    content,
    categories,
    location,
    relatedPosts,
    siteMetadata,
    acf
  } = props;
  const {
    blogSlug,
    categorySlug,
  } = siteMetadata;
  const mainCategory = (categories && categories.length > 0) && categories[0].slug !== 'uncategorised' ? categories[0] : null;
  // console.log(acf.postHeroImage);
  // console.log(featuredImage);
  return (
    <main className="content" role="main">
      <article className="blog-post">
        <div className="blog-post-hero">
          <Image className="featured-image" image={acf && acf.postHeroImage ? acf.postHeroImage : featuredImage} />
          <div className="light-gradient" />
          <div className="wrap">
            <span className="blog-header">BLOG</span>
          </div>
        </div>
        <div className="blog-post-content">
          <div className="wrap">
            <div className="breadcrumbs">
              <span className="crumb"><Link to={`/${blogSlug}/`}>BLOG</Link></span>
              {mainCategory && <span className="crumb"><Link to={`/${categorySlug}/${mainCategory.slug}/`}>{mainCategory.name}</Link></span>}
              <span className="crumb">{decodeEntities(title)}</span>
            </div>
            <div className="post-content-head">
              <div className="title-container">
                {mainCategory &&
                  <span className="category-type">{mainCategory.name}</span>
                }
                <h1>{decodeEntities(title)}</h1>
              </div>
              {/* <AcfComponent componentName="SocialShareBlock" location={location} /> */}
              <SocialShareBlock location={location} />
            </div>
            {(acf && acf.layout && acf.layout.length > 0) ?
              acf.layout.map((item, index) => {
                if (!item.__typename) return null;
                const layoutComponentName = item.__typename.replace('WordPressAcf_', '');
                return (
                  <AcfComponent
                    key={item.id || `layoutComponentName-${index}`}
                    componentName={layoutComponentName}
                    item={item}
                    location={location}
                    siteMetadata={siteMetadata}
                  />
                );
              }) : <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
            }
          </div>
        </div>
        {(relatedPosts && relatedPosts.edges && relatedPosts.edges.length > 0) && (
          <RelatedPosts
            blogSlug={blogSlug}
            relatedPosts={relatedPosts}
            siteMetadata={siteMetadata}
          />
        )}
        <div className="back-link">
          <Link to={`/${blogSlug}/`}>Back to the blog</Link>
        </div>
        {/* <AcfComponent
            componentName="SubscribeFormBlock"
            siteMetadata={siteMetadata}
          /> */}
        <SubscribeFormBlock
          siteMetadata={siteMetadata}
        />
        {/* <AcfComponent
            componentName="TestimonialsBlock"
            hasLink
            link='/contact-us/'
            linkLabel='Book your free consultation now'
            siteMetadata={siteMetadata}
          /> */}
        <TestimonialsBlock
          hasLink
          link='/contact-us/'
          linkLabel='Book your free consultation now'
          siteMetadata={siteMetadata}
        />
        {/* <AcfComponent
            componentName="LogosBlock"
            heading='Some of our valued clients'
            usingGlobalLogos
            siteMetadata={siteMetadata}
          /> */}
        <LogosBlock
          heading='Some of our valued clients'
          usingGlobalLogos
          siteMetadata={siteMetadata}
        />
        {/* <AcfComponent
            componentName="ContactInformationBlock"
            siteMetadata={siteMetadata}
          /> */}
        <ContactInformationBlock
          siteMetadata={siteMetadata}
        />
      </article>
    </main>
  );
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  title: PropTypes.string,
}

const BlogPost = (props) => {
  const {
    data,
    pageContext,
    location,
  } = props;
  const {
    wordpressPost: post,
    relatedPosts,
    site,
  } = data;
  if (!post) return null;
  const {
    title,
    content,
    yoast,
    categories,
    date,
    featuredImage,
    acf = {}
  } = post;
  const { siteMetadata } = site;
  const {
    title: siteTitle,
  } = siteMetadata;
  return (
    <Layout>
      <SEO
        title={yoast.metaTitle ? `${decodeEntities(yoast.metaTitle)}` : `${decodeEntities(title)} | ${siteTitle}`}
        desc={yoast.metaDescription}
        banner={featuredImage && featuredImage.source_url}
      />
      <BlogPostTemplate
        featuredImage={featuredImage}
        content={content}
        categories={categories}
        title={title}
        date={date}
        location={location}
        relatedPosts={relatedPosts}
        siteMetadata={siteMetadata}
        acf={acf}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default Previewable(BlogPost)

export const pageQuery = graphql`
  query BlogPostByID($id: String!, $primaryCategoryId: Int) {
    site {
      siteMetadata {
        title
        wordpressUrl
        blogSlug
        siteUrl
        categorySlug
        functionsUrl
      }
    }
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      content
      date(formatString: "MMMM DD, YYYY")
      yoast {
        title,
        metadesc
      }
      featuredImage: featured_media {
        source_url
        localFile {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      categories {
        name
        slug
      }
      yoast {
        metaTitle: title,
        metaDescription: metadesc
      }
      acf {
        postHeroImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 70) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        },
        layout: layout_post {
          __typename,
          ... on WordPressAcf_AccordionBlock {
            id
            isOpenOnLoad
            blocks {
              blockTitle
              sections {
                type
                heading
                textPosition
                text
                leftColHeading
                leftColumnText
                rightColHeading
                rightColumnText
                image {
                  id
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1200, quality: 70) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                  alt_text
                }
                table {
                  header {
                    c
                  }
                  body {
                    c
                  }
                }
              }
            }
          }
          ... on WordPressAcf_CenteredTextBlock {
            id
            theme
            heading
            text
          }
          ... on WordPressAcf_ContactFormBlock {
            id
            heading
            subheading
            contactFormHeading
          }
          ... on WordPressAcf_FullImageTextBlock {
            id
            theme
            textPosition
            heading
            text
            image {
              id,
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 70) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              },
              alt_text,
            }
          }
          ... on WordPressAcf_ImageFormBlock {
            id
            heading
            subheading
            formPosition
            image {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 70) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              alt_text
            }
          }
          ... on WordPressAcf_ImageLinksBlock {
            id,
            links {
              link
              linkLabel
              image {
                id,
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 70) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
          ... on WordPressAcf_ImageTextBlock {
            id
            theme
            textPosition
            heading
            text
            image {
              id,
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 70) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              },
              alt_text,
            }
          }
          ... on WordPressAcf_NewsBlock {
            id
            heading
            items {
              heading
              text
              link
              image {
                id,
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 70) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
            hasSlant
            slantColor
            slantDirection
          }
          ... on WordPressAcf_ServiceLinksBlock {
            id
            links {
              heading
              text
              link
              icon {
                id
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 70) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
                alt_text
              }
            }
          }
          ... on WordPressAcf_SliderBlock {
            id
            heading
            subheading
            slides {
              heading
              hasLink
              link
              linkLabel
              image {
                id
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 70) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                },
                alt_text
              }
              points {
                text
              }
            }
          }
          ... on WordPressAcf_StandardHero {
            image {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 70) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              alt_text
            }
            hasText
            heading
            text
            hasLink
            link
            linkLabel
          }
          ... on WordPressAcf_StandardTextBlock {
            id,
            theme
            mainHeading
            text
            hasLink
            link
            linkLabel
          }
          ... on WordPressAcf_TabLinks {
            id
            links {
              link
              linkLabel
              image {
                id
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 70) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
                alt_text
              }
            }
          }
          ... on WordPressAcf_ThreeColumnTextBlock {
            id
            theme
            columnOneHeading
            columnOneContentType
            columnOneText
            columnOnePoints {
              point
            }
            columnTwoHeading
            columnTwoContentType
            columnTwoText
            columnTwoPoints {
              point
            }
            columnThreeHeading
            columnThreeContentType
            columnThreeText
            columnThreePoints {
              point
            }
          }
          ... on WordPressAcf_VideoBlock {
            id
            heading
            videoUrl
            videoProvider
          }
        }
      }
    }
    relatedPosts: allWordpressPost(
      filter: {categories: {elemMatch: {wordpress_id: {eq: $primaryCategoryId}}}, id: {ne: $id}},
      sort: {fields: date, order: DESC},
      limit: 3
    ) {
      edges {
        node {
          slug
          title
          excerpt
          date(formatString: "DD.MM.YYYY")
          featuredImage: featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 70) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          categories {
            slug
            name
          }
        }
      }
    }
  }
`
