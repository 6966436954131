import React from 'react';
import { Link } from 'gatsby';
import Image from '../Image';
import './RelatedPosts.scss';

export const RelatedPosts = (props) => {
  const { blogSlug, relatedPosts } = props;
  const { edges } = relatedPosts;
  const relatedPostItems = edges.map(postItem => postItem.node);
  return (
    <div className="related-posts">
      <div className="wrap">
        <span className="heading">Related Posts</span>
        <ul>
          {relatedPostItems.map((post) => {
            const {
              slug,
              featuredImage,
              title,
              author,
              date,
            } = post;
            return (
              <li key={slug}>
                <Link to={`/${blogSlug}/${slug}/`}>
                  <div className="inner-image">
                    <Image image={featuredImage} />
                  </div>
                  <div className="text-block">
                    <span className="title" dangerouslySetInnerHTML={{__html: title}} />
                    {/* <span className="author" dangerouslySetInnerHTML={{__html: author.name}} /> */}
                    {/* <time className="date">{date}</time> */}
                  </div>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  );
};
